.buttonDonation{
  outline:none !important;
  height: 3.5em !important;
  text-align: center !important;
  width: 10em !important;
  border-radius: 545px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 4px solid #05D39B !important;
  color:#05D39B !important;
  fill:#05D39B !important;
  letter-spacing:1px !important;
  text-shadow:0 !important;
  font-size: 150% !important;
  font-style: bold !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin-right: 1%;
  margin-top: 2.5%;
}

.buttonDonation:hover {
  color:white !important;
  fill:white !important;
  background: #05D39B !important;
}

.heart {
  width: 10%;
  margin-right: 8%;
  margin-top: 6%;
}