.titleValues {
  color: #6A7C8D;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 120%;
  letter-spacing: 1.2px;
}
.txtValues {
  color: #6A7C8D;
  margin-top: 1%;
  padding-bottom: 3.5%;
  font-family: 'Muli', sans-serif;
  text-align: center;
  font-size: 110%;
  font-style: normal;
  font-weight: Regular;
  font-stretch: normal;
  margin-left: 10%;
  margin-right: 10%;
  }
.iconsSize {
  width: 25% !important;
}
/*************************************************/
/**** Versión mobile de Icons Values *************/
/*************************************************/
@media only screen and (min-width: 569px ) and (max-width: 990px ) {
  .iconsSize {
    width: 40% !important;
  }
  .marginValues {
    padding-left: 0% !important;
  }
  .txtValues{
    font-size: 100%;
  }
  }
  @media only screen and (min-width: 565px ) and (max-width: 568px ) {
    .iconsSize {
      width: 20% !important;
    }
    .marginValues {
      padding-left: 0% !important;
    }
   
  }
@media only screen and (min-width: 331px ) and (max-width: 564px ) {
  .iconsSize {
    width: 40% !important;
    margin-bottom: 0px !important;
    margin-top: 6.5%;
  }
 
}
@media only screen and (min-width: 0px ) and (max-width: 330px ) {
  .iconsSize {
    width: 40% !important;
  }
  .marginValues {
    padding-left: 0% !important;
  }
}

