.donation-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.donation-buttons button {
  margin: 0 !important;
  height: 80px !important;
}

.donation-buttons button:nth-child(1) {
  margin-right: 30px !important;
  margin-left: 30px !important;
  margin-bottom: 20px !important;
}

@media (max-width: 991.98px) {
  .donation-buttons {
    display: flex;
    justify-content: center;
  }
  .donation-buttons button {
    display: block;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 300px !important;
  }

  .donation-buttons button:nth-child(1) {
    margin-right: 30px !important;
    margin-left: 30px !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 767.98px) {
  .donation-buttons button {
    display: block;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .donation-buttons button {
    display: block;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 280px !important;
  }

  .donation-buttons button:nth-child(1) {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 20px !important;
  }
}
