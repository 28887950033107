.cardVolunteer {
  background-color: #e7e9f2 !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4) !important;
  border-radius: calc(1rem - 1px) !important;
  padding: 10%;
}
.logoVolunteer{
  width: 37%;
  display: block;
  margin: 5% auto;
}
.titleText{
  font-size: 160%;
  color: #05D39B;
  font-style: bolder;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
}
.txt{
  text-align: center;
  color: #6A7C8D;
  font: bold;
  font-family: 'Muli', sans-serif;
}
.txtAuxiliarVolunteer{
  font-size: 110%;
  margin-top: 5%;
  color: #6A7C8D;
  font-family: 'Muli', sans-serif;
}
.volunteerTxt {
  text-align: center;
}


@media only screen and (max-width: 820px ) {
  .txtAuxiliarVolunteer{
    padding-bottom: 15%;
  } 
}
@media only screen and (min-width: 760px ) and (max-width: 762px ) {
  .txtAuxiliarVolunteer{
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 0px ) and (max-width: 500px ) {
  .cardVolunteer {
    background-color: #EFF0F4 !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4) !important;
    border-radius: calc(1rem - 1px) !important;
    margin-bottom: 5% !important;
    padding-bottom: 9%;
    padding-top:10%;
    margin-left: 0 !important;
  }
  .txt{
    color: #6A7C8D;
    font: bold;
    margin-bottom: 3%;
    font-family: 'Muli', sans-serif;
  }
  .titleText {
    font-size: 150%;
  }
  .txtAuxiliarVolunteer{
    color: #6A7C8D;
    margin-bottom: 5%;
    padding-bottom: 5%;
    font-family: 'Muli', sans-serif;
  }
  .logoVolunteer{
    width: 50%;
  }
}



