.logoDTS {
  width: 25%;
}
.title {
  font-size: 90%;
  color: #506FF4;
  font-family: 'Noto Sans JP', sans-serif;
}
.txtTitle {
  font-weight: 900;
  text-transform: uppercase;
}
.txtTitle,
.txtContent {
  color: #6A7C8D;
  font-family: 'Muli', sans-serif;
  text-align: left;
}

.titleContent {
  text-transform: uppercase;
}

.fondoInformationDTS {
  background-image:
    url("../../../../assets/img/Icons/background/circulong-01.svg"),
    url("../../../../assets/img/Icons/background/plus_bg-01.svg");
  background-position-x: 92vw, -3.8vw;
  background-position-y: 2rem, 30rem;
  background-repeat: no-repeat;
  background-size: 12%;
  padding: 4% 0;
}

/*************************************************/
/********** Versión mobile de dts    *************/
/*************************************************/

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoInformationDTS {
    background-position-y: 2rem, 60rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoInformationDTS {
    background-position-x: 84vw, -3.8vw;
    background-position-y: 0rem, 60.5rem;
    background-size: 25%;
  }
  .logoDTS {
    width: 45%;
  }
}

@media only screen and (max-width: 576px) {
  .logoDTS {
    width: 65%;
  }
  .txtTitle,
  .txtContent {
    text-align: center;
  }
}