@import url("https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.css");
@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");

.btn-xl {
  padding: 1.25rem 2.5rem; }

.content-section {
  padding-top: 6rem;
  padding-bottom: 5rem;
}

.content-section-heading h2 {
  font-size: 3rem; }

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

/* Map */
.map {
  height: 30rem; }

@media (max-width: 992px) {
  .map {
    height: 75%; } }

.map iframe {
  pointer-events: none; }

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  opacity: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  line-height: 45px; }
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: white; }
  .scroll-to-top:hover {
    background: #343a40; }
  .scroll-to-top i {
    font-weight: 800; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

.fas {
  line-height: inherit; }

.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url("../img/portFolio/img/bg-masthead.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .masthead h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0; }
  @media (min-width: 992px) {
    .masthead {
      height: 100vh; }
      .masthead h1 {
        font-size: 5.5rem; } }





.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: #fff;
   }






.service-icon {
  background-color: #fff;
  color: #1d809f;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }

.icon-pencil,
.icon-screen-smartphone,
.icon-mustache,
.icon-like {
  line-height: inherit; }

.callout {
  padding: 0;
  color: rgba(54, 52, 52, 0.459);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
  
.callout h2 {
  font-size: 3.5rem;
  font-weight: 700;
  display: block;
  max-width: 30rem;
}

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

  .portfolio-item .caption .caption-content {
    color: #fff;
    margin: auto 2rem 2rem;
  }

  .portfolio-item .caption .caption-content h2 {
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .portfolio-item .caption .caption-content p {
    font-weight: 300;
    font-size: 1.2rem;
  }
        
  @media (min-width: 992px) {
    .portfolio-item {
      max-width: none;
      margin: 0; }
      div.portfolio-item .caption {
        -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
        -webkit-clip-path: inset(0px);
        clip-path: inset(0px); }
        div.portfolio-item .caption .caption-content {
          transition: opacity 0.25s;
          margin-left: 5rem;
          margin-right: 5rem;
          margin-bottom: 5rem; }
     div.portfolio-item img {
        -webkit-transition: -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(-1px);
        clip-path: inset(-1px); }
      div.portfolio-item:hover > img {
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); }
       div.portfolio-item:hover .caption {
        /* background-color: rgba(29, 128, 159, 0.9); */
        background-color: rgba(34, 48, 71, 0.541);
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); } }

footer.footer {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  footer.footer .social-link {
    display: block;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem !important;
    font-size: 1.5rem;
    background-color: #41b38f;
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }
    footer.footer .social-link:hover {
      background-color: #155d74;
      text-decoration: none; }

.icon-social-facebook,
.icon-social-twitter,
.icon-social-github {
  line-height: inherit; }

a {
  color: #1d809f; }
  a:hover, a:focus, a:active {
    color: #155d74; }

.btn-primary {
  background-color: #1d809f !important;
  border-color: #1d809f !important;
  color: #fff !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #155d74 !important;
    border-color: #155d74 !important; }

.btn-secondary {
  background-color: #ecb807 !important;
  border-color: #ecb807 !important;
  color: #fff !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #ba9106 !important;
    border-color: #ba9106 !important; }

.btn-dark {
  color: #fff !important; }

.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700; }

.bg-primary {
  background-color: #1d809f !important; }

.text-primary {
  color: #1d809f !important; }

.text-secondary-p {
  color: #ecb807 !important; }

.card-nav {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    /* border: 1px solid rgba(0,0,0,.125); */
    /* border-radius: .25rem; */
}  

.car-header-nav {
    font-size: 1.2rem;
    background: rgba(52, 58, 64, 0.1);
    height: 80px;
    line-height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    
    border-bottom: 1px solid rgba(243, 237, 237, 0.1);
}

.car-header-nav > .card-link {
  color: #fff;
}

.card-body-nav {
    display: block;
    text-decoration: none;
    color: #fff;

}

.card {
  max-width: 100%;
}
.card-text {
  word-wrap: break-word;
}


.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 50px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: justify;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 5px;
  max-width: 500px !important;;
}

.portfolio-item {
  margin-bottom: 30px;
}

.bg-solidaria {
  background-color: #41b38f !important; }

  .bg-solidaria2 {
    background-color: #1d809f !important; }


.bg-solidaria .nav-item > a {
  color: #fff;
}

.bg-solidaria .nav-item > a:hover {
  color: #f3f3f3;
  background-color: #85d0b8;
  border-color: #85d0b8;
}

.btn-solidaria-g {
    color: #fff;
    background-color: #41b38f;
    border-color: #41b38f;
}

.btn-solidaria-g:hover {
  color: #fff !important;
  background-color: #41b38f;
}

.btn-primary {
  background-color: #213363 !important;
  border-color: #213363 !important;
  color: #fff !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #2e3a6f96 !important;
  border-color: #2e3a6f00 !important;
}


.btn-light {
  color: #3e7384;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-light:hover {
  color: #f3f3f3;
  background-color: #85d0b8;
  border-color: #85d0b8;
}


.text-block {
  position: absolute;
  left: 30px;
  bottom: 30px;
  max-width: 400px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
}

.content-section-sm {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

footer.footer {
  padding-top: 5rem;
  padding-bottom: 1.5rem;
}


.imwe {
  width: 250px;
  height: 250px;
  border-radius:150px;
}

.azul {
  background-color: #41b38f;
  color: #fff;
}
.azul2 {
  background-color: #1d809f;
  color: #fff;
}

.imgRedonda {
  width:300px;
  height:300px;
  border-radius:150px;
  border:5px solid #41b38f;
}

.imgRedonda:hover {
  -webkit-transform:scale(1.1);
  transform:scale(1.1);
}

.fondo {
  color: #fff;
}


.medioo {
  background-image: url('../img/logo-nav-w.png') ;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 90px;
  margin-top: 50px;
  }

.ama {
  color : #EEE300;
}

.ama2 {
  height: 3px;
  background-color : #EEE300;
}

.counter {
  text-align: center;
  position: relative;
  color: #41b38f;
}
.counter-inner{           
  position: relative;
  z-index: 1;
 -webkit-clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0% 75%, 0 0);
  clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0% 75%, 0 0); 
  background: #10a2e6;  
}
.counter.white .counter-inner,
.counter.white .counter-icon{
  background: #fff;
}

.counter-inner:before,
.counter-icon:before{
  content: "";
  position: absolute;
  top:0;
  right:0;
  width: 50%;
  height: 100%;
  background: rgba(0,0,0,0.15);
  z-index: -1;
}
.counter-value{
  display: inline-block;
  border: 10px solid #41b38f;
  width: 150px;
  height: 150px;
  line-height: 130px;
  background: #10a2e6;
  border-radius:50%;
  color: #41b38f;
  font-size: 45px;
  font-weight:700;
  position: relative;
  box-shadow: inset 0 0 15px rgba(0,0,0,0.3);
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  margin-bottom: 20px;
}
.counter.white .counter-value{
  background: #fff;
}

.counter h3 {
  margin: 0 ;
  font-size: 20px;
  font-weight:600;
  text-transform: uppercase;
}
.counter-icon{
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 120px;
  background: #10a2e6;
  border-radius:0 50px 0 50px;
  font-size: 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;            
}
.counter-icon:after{
  content: "";
  position: absolute;
  top:7px;
  left:7px;
  right:7px;
  bottom:7px;
  border:5px solid #41b38f;
  border-radius:0 50px 0 50px;
  box-shadow: inset 0 0 15px rgba(0,0,0,0.3);
}
@media screen and (max-width:990px) {
  .counter {
      margin-bottom: 30px;
  }
}

.grande {
  width: 300px;
  height: 350px;
}

.linea {
  height: 5px;
  background-color: #41b38f;
}

.linea2 {
  height: 5px;
  background-color: #1d809f;
}

.share {
	position: relative;
	display: inline-block;
}

.share a {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 13px;
	text-decoration: none;
	text-align: center;
	
}

.share > a {
	display: inline-block;
	width: 250px;
	z-index: 1;
	font-weight: bold;
	color: #000;
	border: 1px solid #155d74;
	border-radius: 3px;
	background: #fff;
	background-image: -webkit-linear-gradient(top, #FAFBFD, #F6F7FC);
	background-image: -moz-linear-gradient(top, #FAFBFD, #F6F7FC);
}

.share > a:hover {
	background: #eee;
	background-image: -webkit-linear-gradient(top, #F4F7FC, #FAFBFF);
	background-image: -moz-linear-gradient(top, #F4F7FC, #FAFBFF);
}

.share div {
	position: absolute;
	width: 250px;
	height: 8px;
	overflow: hidden;
	left: 2px;
	top: 28px;
	-webkit-transition: all 150ms linear;
	-moz-transition: all 150ms linear;
	-o-transition: all 150ms linear;
	transition: all 150ms linear;
}

.share:hover div {
	height: 94px;
	/*
	 * 26px (height of <li>) times the number of <li>s, plus 16px for grippy
	 */
}

.share ul {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 250px;
	background: #56A0F9;
	background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.15), transparent);
	margin: 0;
	
	border-radius: 0 0 3px 3px;
	border: 1px solid rgba(0,0,0,0.25);
	border-width: 0 1px 1px;
	box-shadow: 0 -1px 0 rgba(255,255,255,0.5) inset;
	z-index: 9999;
}

.share ul::before {
	content: '.';
	text-indent: -9999px;
	display: block;
	background: #56A0F9;
	width: 15px;
	height: 15px;
	position: absolute;
	bottom: -4px;
	left: 38px;
	border-radius: 15px;
	box-shadow:
		0 -1px 0 rgba(255,255,255,0.5) inset,
		0 1px 0 #000;
	z-index: 0;
}

.share ul::after {
	content: '.';
	text-indent: -9999px;
	display: block;
	background: #fff;
	width: 5px;
	height: 5px;
	position: absolute;
	bottom: -2px;
	left: 42px;
	border-radius: 15px;
	border: 1px solid #155d74;
	box-shadow: 0 -1px 0 rgba(255,255,255,0.5);
	z-index: 0;
}

.share.neutro ul {
  background: #41b38f;
}
.share.neutro ul:before {
  background: #155d74;
}

.share:hover ul {
	bottom: none;
}

.share ul li {
	list-style: none;
	margin: 0;

}

.share ul li a {
	display: block;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0,0,0,0.25);
}

.share ul li a:hover {
	text-decoration: underline;
}

.negro {
  background-color: #e5eaec;
}
.negro img {
 width: 20px;
 height: 20px;
}

#photos {
  width: 300px;
  height: 300px;
  margin-bottom: 40px;
}


.core {
  width: 300px;
  height: 300px;
}
.jus {
  text-align: justify;
  margin-left: 160px;
}
.peque {
  width: 90px;
  height: 90px;
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40vh;
  margin-left: 70px;
}

.item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex .9s ease;
}

.h2c {
  color: #213363;
}
  
  .item:hover {
    flex: 7;
  }

.color-i { 
  background-color: #41b38f;
}

.seccion1{
  height: 50vh;
  width: 100%;
  background-image: url('../img/home.jpg');
  background-size: cover;
  display: table;
  background-attachment: fixed;
}
.sec {
  height: 400px;
}
 .down {
   margin-top: 0;
 }


/*
* Circle Buttons 
*/
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.disabled-link {
  pointer-events: none;
}
.margen {
  padding-left: 80px;
  padding-right: 80px;
}



.card2__container {
  position: relative;
  border-radius: calc(2rem - 2px);
  /* width: 50%; */
}

.card2__image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.card2__middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.card2__container:hover {
  background-color: #2d2d2d;
}

.card2__container:hover .card2__image {
  opacity: 0.5;
}

.card2__container:hover .card2__middle {
  opacity: 1;
}

.card2__text {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.card2__middle p {
  color: whitesmoke;
}