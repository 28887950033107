.title {
  font-size: 90%;
  color: #506FF4;
  font-style: bold;
  margin-top: 10%;
  padding-bottom: 3%; 
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitle{
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  margin-bottom: 6%;
  font-family: 'Noto Sans JP', sans-serif;
}
.txt{
  color: #6A7C8D;
  text-align: center !important;
  font-family: 'Muli', sans-serif;
  padding-right: 10%;
  padding-left: 10%;
}
.txt_justify {
  text-align: justify !important;
}

.logoDare {
  width: 22%;
  padding-bottom: 3.5%;
}

.fondoDare {
  background-image: url("../../../assets/img/Icons/background/flechabg.svg"), url("../../../assets/img/Icons/background/circulong-01.svg");
  background-position-x: -5vw, 85vw;
  background-position-y: 23rem, -4.5rem;
  background-repeat: no-repeat, no-repeat;
  background-size: 12%;
  padding-top: 5%;
  padding-bottom: 4.5%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoDare {
    background-position-y: 24rem, -3rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 769px) {
  .fondoDare {
    background-position-y: 36rem, -2rem;
    background-size: 25%;
  }
}

/*************************************************/
/******* Versión mobile de DareToShare ***********/
/*************************************************/



@media only screen and ( min-width: 1024px )  and (max-width: 1155px ) {
  .logoDare {
    width: 30%;
    margin-top: 1%;
    padding-bottom: 5%;
  }
}

@media only screen and ( min-width: 812px )  and (max-width: 900px ) {

  .logoItems {
    width: 50%;
    margin-top: 0%;
  }
  .logoDare {
    width: 50%;
    /* height: 40%; */
    margin-top: 1%;
    margin: 2% 0;
    padding-bottom: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .fondoDare {
    padding: 10% 0;
  }
}

@media only screen and ( min-width: 736px )  and (max-width: 811px ) {
  .logoItems {
    width: 70%;
    height: 70%;
    margin-top: 0%;
  }
  .logoDare {
    width: 40%;
    /* height: 40%; */
    padding-bottom: 5%;
    margin: 2% 0;
  }
  .separacionItems{
    height: 10em;
    margin-left: 10%;
    margin-top: 2%;
  }
}

@media only screen and (min-width: 640px ) and (max-width: 735px ) {
  .logoItems {
    width: 50%;
    height: 50%;
    margin-top: 0%;
  }
  .logoDare {
    width: 60%;
    margin-top: 1%;
    padding-bottom: 5%;
  }
}
@media only screen and (min-width: 501px ) and (max-width: 568px ) {
  .logoItems {
    width: 50%;
    height: 50%;
    margin-top: 1%;
  }
  .logoDare {
    width: 60%;
    margin-top: 1%;
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 331px ) and (max-width: 500px ) {
  .logoItems {
    width: 50%;
    height: 50%;
    margin-top: 1%;
  }
  .logoDare {
    width: 60%;
    margin: 5% 0;
  }
  .separacionItems{
    margin-left: 10%;
  }
}

@media only screen and (min-width: 0px ) and (max-width: 330px ) {
  .logoItems {
    width: 50%;
    height: 50%;
    margin-top: 1%;
    margin-left: 2%;
  }
  .logoDare {
    width: 70%;
    margin: 5%;
  }
  .separacionItems{
    margin-left: 10%;
  }
}



