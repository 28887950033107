/* Texto de los iconos */
.txtIcons {
  color: #6A7C8D;
  padding-bottom: 2%;
  font-family: 'Muli', sans-serif;
  text-align: left !important;
  font-size: 110%;
}

/*************************************************/
/**** Versión mobile de Icons Projects ***********/
/*************************************************/

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .iconsSize {
    width: 54.5%;
    margin-bottom: 0% !important;
  }
  .txtIcons {
    text-align: center !important;
  }
}