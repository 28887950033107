.tag-container {
  background: #e7e9f2;
  width: 220px;
  color: #5f6472;
  border-radius: 24px;
  padding: 1.5%;
  text-transform: uppercase;
  margin: 0.5%;
  font-size: 90%;
}
.tag-icon {
  font-size: 14px;
}
.tag {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 75%;
  height: 59px;
  margin-left: 0.5%;
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
  .tag-container {
    margin: 1%;
  }
}
