.buttonInitiative {
  outline:none !important;
  height: 4em !important;
  text-align: center !important;
  width: 14em !important;
  border-radius:35px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 4px solid #05D39B !important;
  color:#05D39B !important;
  letter-spacing:1px !important;
  font-size: 120% !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  box-shadow: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 1.5% !important;
  font-weight: 600;
}

.buttonInitiative:hover,
.buttonSend:hover {
  color:white !important;
  background: #05D39B !important;
}

.buttonSend {
  outline: none !important;
  text-align: center !important;
  width: 7em !important;
  border-radius: 30px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 4px solid #05D39B !important;
  color:#05D39B !important;
  letter-spacing:1px !important;
  font-size: 120% !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  box-shadow: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
}

.logo {
  margin-top:2%;
  width: 2%;
  margin-right: 1%;
  margin-left: 1%;
}

.label {
  color:#6A7C8D;
  font-style: bold;
  font-family: 'Noto Sans JP', sans-serif;
}

.input {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 90%;
  padding: 1.4rem !important;
  border-radius: 17px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .buttonInitiative {
    margin-bottom: 11% !important;
  }
}