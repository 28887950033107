.logoItems {
  width: 35%;
}

.txt {
  font-size: 140%;
  color: #6A7C8D;
  text-align: center !important;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
}

@media only screen and (min-width: 736px)  and (max-width: 811px) {
  .logoItems {
    width: 70%;
    height: 70%;
    margin-top: 0%;
  }
}

@media only screen and (max-width: 735px) {
  .logoItems {
    width: 50%;
    height: 50%;
  }
}




