.optionsPrograms{
  font-family: 'Muli', sans-serif;
  font-weight: normal;
  color: #6A7C8D;
  text-align: left;
  font-size: 95%;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.30;
  letter-spacing: normal;
 }

 @media only screen and (min-width: 360px ) and (max-width: 640px){
  .optionsPrograms > p > span {
    font-size: 95%;
  }
}

 