.fondoPlusPrograms {
  background-image: url("../../../../assets/img/Icons/background/plus_bg-01.svg");
  background-position-x: 89vw;
  background-position-y: 3rem;
  background-repeat: no-repeat;
  background-size: 11%;
  padding: 2% 0;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoPlusPrograms {
    background-position-y: 4rem;  
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoPlusPrograms {
    background-position-x: 85vw;
    background-size: 25%;
  }
}
