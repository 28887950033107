.mov{
  margin-left: 100px;
}
.marg{
  margin-left: 250px;
}

@media only screen and (min-width: 1700px)  {
  .movlg {
    margin-left: 170px;
  }
}
@media only screen and (min-width: 2000px)  {
  .movlg {
    margin-left: 300px;
  }
}
.movLeft{
  margin-left: 75px;
}

.imCircle {
  width: 150px;
  height: 150px;
  border-radius: 40px;
}