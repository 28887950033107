/**************************************/
/************       HOME   ************/
/**************************************/
.clean__main_layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.clean__main_layout-content {
  flex: 1;
}

/* Imagen agregada en contenido de proyecto ajustada
  al tamaño del cotenedor */
.txtProject img {
  width: 100%;
}

/* Elementos en el contenido de proyecto ajustados al
  tamaño de contenedor */
.txtProject > * {
  width: 100%;
}

.react_boostrap_table__small_table table.table.table-bordered {
  display: inline-table !important;
}

.title {
  font-size: 90%;
  color: #506ff4;
  font-style: bold;
  padding-bottom: 3%;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitle {
  font-size: 290%;
  color: #092c66;
  font-style: bolder;
  margin-bottom: 2%;
  font-family: 'Noto Sans JP', sans-serif;
}
.txt {
  color: #6a7c8d;
  font-family: 'Muli', sans-serif;
}
@media only screen and (min-width: 321px) and (max-width: 500px) {
  .subtitle {
    font-size: 230%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .subtitle {
    font-size: 220%;
  }
}
/**************************************/
/************    SOFTTEKIAN  **********/
/**************************************/
.labelSof {
  font-size: 90%;
  color: #506ff4;
  font-style: bold;
  padding-bottom: 3%;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 0% !important;
  padding-bottom: 0% !important;
}
.skillsSof {
  font-size: 80%;
  color: #092c66;
  font-style: bolder;
  margin-bottom: 2%;
  font-family: 'Noto Sans JP', sans-serif;
}
.txt {
  color: #6a7c8d;
  font-family: 'Muli', sans-serif;
}
.txtBadge {
  color: #092c66 !important;
}
.badgeSof {
  background-color: rgba(239, 240, 244, 0.685);
  font-size: 100%;
}
.breadSof {
  background-color: #05d39b;
  color: #eff0f4;
  font-family: 'Muli', sans-serif !important;
  font-size: 90%;
}
.nav-tabs .nav-link.active {
  color: #05d39b !important;
}
.labelSof {
  color: #6a7c8d;
  font-style: bold;
  font-family: 'Noto Sans JP', sans-serif;
}
.buttonProfile {
  outline: none !important;
  height: 3em !important;
  text-align: center !important;
  width: 15em !important;
  border-radius: 40px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 4px solid #05d39b !important;
  color: #05d39b !important;
  letter-spacing: 1px !important;
  text-shadow: 0 !important;
  font-size: 100% !important;
  font-style: bold !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
}
.buttonProfile__wrapper {
  display: flex;
  justify-content: center;
  margin: 5%;
}
.buttonProfile:hover {
  color: white !important;
  background: #05d39b !important;
}
.logoSof {
  margin-top: 0%;
  margin-bottom: 0% !important;
  width: 3%;
  margin-right: 1%;
  margin-left: 1%;
}
.logoSofG {
  margin-top: 0%;
  margin-bottom: 0% !important;
  width: 1.5%;
  margin-right: 0.5%;
  margin-left: 1%;
}
/**************************************/
/************    ALL PAGES   **********/
/**************************************/

.titlePages {
  font-size: 90%;
  color: #506ff4;
  font-style: bold;
  margin-top: 7%;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitlePages {
  font-size: 290%;
  color: #092c66;
  font-style: bolder;
  margin-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}
.txtPages {
  color: #6a7c8d;
  margin-top: 1%;
  padding-bottom: 4%;
  font-family: 'Muli', sans-serif;
  text-align: left;
}

@media only screen and (min-width: 321px) and (max-width: 500px) {
  .subtitlePages {
    font-size: 230%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .subtitlePages {
    font-size: 220%;
  }
}

/**************************************/
/********  PAGE PROGRAMS   ************/
/**************************************/
.iconsPrograms {
  width: 86%;
}
.txtPrograms {
  padding-top: 0.5%;
  color: #6a7c8d;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 120%;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.2px;
}
.contentPrograms {
  font-family: 'Muli', sans-serif;
  font-weight: normal;
  color: #6a7c8d;
  text-align: left;
  font-size: 117%;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
}
.optionsPrograms {
  font-family: 'Muli', sans-serif;
  font-weight: normal;
  color: #6a7c8d;
  text-align: left;
  font-size: 95%;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
}
@media only screen and (min-width: 500px) {
  .iconsPrograms {
    padding-right: 2%;
    width: 100%;
  }
  .subtitlePages {
    font-size: 190% !important;
  }
}
@media (max-width: 767.98px) {
  .iconsPrograms {
    width: 40% !important;
    margin-bottom: 0% !important;
  }
  .txtPrograms {
    text-align: center;
  }
}
@media only screen and (min-width: 900px) {
  .iconsPrograms {
    padding-right: 2%;
    width: 45%;
  }
}
@media only screen and (min-width: 1024px) {
  .iconsPrograms {
    padding-right: 2%;
    width: 86%;
  }
}

/**************************************/
/********  CAROUSEL        ************/
/**************************************/

.carousel-indicators li {
  background-color: rgba(9, 45, 102, 0) !important;
}

/**************************************/
/********  INGRESAR        ************/
/**************************************/

.buttonIngresar {
  margin-left: 40% !important;
  margin-bottom: 10% !important;
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .buttonIngresar {
    margin-left: 25% !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .buttonIngresar {
    margin-left: 35% !important;
  }
}

/********************************************/
/** projects, news and convocatory preview **/
/********************************************/

.titleProject {
  font-size: 100%;
  color: #1b63cf;
  font-weight: 500;
  padding-bottom: 3%;
  padding-top: 3%;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitleProject {
  font-size: 200%;
  color: #092c66;
  font-style: bolder;
  padding-top: 3%;
  padding-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitleProject_gallerie {
  font-size: 150%;
  color: #092c66;
  font-style: bolder;
  padding-top: 3%;
  padding-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}
.txtProject {
  color: #6a7c8d;
  font-size: 15px;
  font-family: 'Muli', sans-serif;
  margin-top: 5%;
}

.txtProject a {
  word-break: break-all;
}

.txtProject * {
  font-family: 'Muli', sans-serif;
}
.autor {
  color: #092c66;
  font-size: 100%;
  font-family: 'Muli', sans-serif;
  margin-bottom: 10% !important;
  margin-top: 5%;
}
.subirTexto {
  margin-bottom: 30%;
}

.contenedorProject {
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.contenedorGalle {
  margin-left: 10% !important;
  margin-right: 10% !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.miniCard {
  border-top-left-radius: calc(1.5rem - 1.5px);
  border-top-right-radius: calc(1.5rem - 1.5px);
}
.miniCardHeader {
  border-top-left-radius: calc(1rem - 2.5px) !important;
  border-top-right-radius: calc(1rem - 2.5px) !important;
  background-color: #f2c346;
  color: #092c66;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: bold;
  letter-spacing: 1.5px;
  font-weight: 600;
  text-align: center;
  padding: 20px 15px;
}
.miniCardHeader > h4 {
  font-size: 140% !important;
}
.miniCardBody {
  background-color: rgba(239, 240, 244, 0.685);
}
.textoLeyenda {
  margin-top: 5%;
  margin-bottom: 5%;
  color: #6a7c8d;
  font-family: 'Muli', sans-serif;
}
.counterSymbol{
  font-size: 30%;
}
.buttonProjectPage__wrapper {
  display: flex;
  justify-content: center;
}
.buttonProjectPage {
  outline: none !important;
  /* height: 3em !important; */
  padding: 0.6rem;
  text-align: center !important;
  width: 12em !important;
  border-radius: 40px !important;
  background: #506ff4;
  color: white !important;
  letter-spacing: 1px !important;
  text-shadow: 0 !important;
  font-size: 115% !important;
  font-style: bold !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin: auto;

  /* margin-left: 25%; */
}

.buttonProjectPageUnsubscribe {
  outline: none !important;
  height: 3em !important;
  text-align: center !important;
  width: 10em !important;
  border-radius: 40px !important;
  background: #e74a3b !important;
  color: white !important;
  letter-spacing: 1px !important;
  text-shadow: 0 !important;
  font-size: 150% !important;
  font-style: bold !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
  /* margin-left: 25%; */
}

.buttonProjectPageUnsubscribe:hover {
  background-color: #c03e32 !important;
}

.buttonProjectPage:hover {
  color: white !important;
  background: #092c66 !important;
}

.buttonConvocatory {
  outline: none !important;
  /* height: 3em !important; */
  text-align: center !important;
  /* width: 10em !important; */
  border-radius: 40px !important;
  background: #506ff4 !important;
  color: white !important;
  letter-spacing: 1px !important;
  text-shadow: 0 !important;
  font-size: 90% !important;
  font-style: bold !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin-left: 25%;
  text-transform: uppercase;
  padding: 15px;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(2rem - 2px);
  border-top-right-radius: calc(2rem - 2px);
}
.buttonConvocatory:hover {
  color: white !important;
  background: #092c66 !important;
}
.box-shadowCard {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.statusProject {
  background-color: #05d39b;
  color: #eff0f4 !important;
  letter-spacing: 1px;
  font-size: 90%;
}
.listaEtiquetas {
  align-self: auto;
  margin: 0 0.5rem;
}
.txtEtiqueta {
  font-family: 'Muli', sans-serif;
  color: #6a7c8d;
  background-color: rgba(239, 240, 244, 0.685);
  font-size: 90%;
}
.imgConvocatory {
  margin-left: 15%;
}

.terms-conditions-small-container {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #2d2d2d8f;
  margin: 20px 10px;
  word-break: normal;
  text-align: justify;
}

.word-break-normal {
  word-break: normal;
}

.terms-conditions-small-container * {
  font-size: 11px;
}

.pointer-events-none {
  pointer-events: none;
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  /* .buttonProjectPage{
    margin-left: 5% !important;
  } */
  .buttonConvocatory {
    margin-left: 0%;
  }
  .imgConvocatory {
    margin-left: 5%;
  }
  .gallerie_subirtexto {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  /* .buttonProjectPage{
    margin-left: 25% !important;
  } */
  .buttonConvocatory {
    margin-left: 0%;
  }
  .imgConvocatory {
    margin-left: 3%;
  }
  .gallerie_subirtexto {
    width: 100%;
  }
}
@media only screen and (max-width: 1680px) {
  /* .buttonProjectPage{
    margin-left: 10%;
  } */
}

/********************************************/
/**                 contact                **/
/********************************************/

.contact__input-select-subsede > div {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  padding: 0.28rem !important;
  color: #6a7c8d !important;
  border-radius: 17px;
  font-weight: 600;
}

/********************************************/
/**               carousel                 **/
/********************************************/

.carousel-indicators {
  bottom: 150px;
}

.carousel-indicators li {
  width: 6px;
  height: 6px;
  background-color: rgba(230, 230, 230, 0.514) !important;
  border: 2px solid #ffffff !important;
  border-radius: 50%;
  margin: 10px;
}

.carousel-indicators li.active {
  background-color: #ffffff !important;
}

@media (max-width: 769px) {
  .carousel-indicators {
    bottom: 160px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }
}

.txt-customer-project {
  color: #6a7c8d;
  font-size: 15px;
  font-family: 'Muli', sans-serif;
  margin-top: 5%;
  margin-bottom: 10%;
}

.txt-customer-project img,
.txt-customer-project iframe {
  max-width: 100%;
}

.txt-customer-project iframe {
  display: block;
  margin: auto;
}

.txt-customer-project * {
  font-family: 'Muli', sans-serif;
}

.txt-customer-project a {
  word-break: break-all;
}

.txt-customer-project h1,
.txt-customer-project h2 {
  font-size: 1rem;
}

.customer-project-card-header {
  text-align: center;
  background: #223047;
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 15px;
  border-radius: 5px;
  /* font-family: 'Muli', sans-serif; */
}

.customer-project-card button,
.customer-project-card > * {
  font-family: 'Muli', sans-serif;
}

.customer-project-card-header h4 {
  font-size: 20px !important;
}

.form-stepper div span,
.form-stepper div a {
  line-height: 30px !important;
}

.customer-project-gallery-header {
  font-family: 'Muli', sans-serif;
}

.customer-project-gallery-header h2 {
  font-family: 'Noto Sans JP', sans-serif !important;
}

.underlined-text {
  text-decoration: underline;
}

.txtProject {
  color: #6a7c8d;
  font-size: 15px;
  font-family: 'Muli', sans-serif;
  margin-top: 5%;
  margin-bottom: 10%;
}

.txtProject img,
.txtProject iframe {
  max-width: 100%;
}

.txtProject iframe {
  display: block;
  margin: auto;
}

.txtProject * {
  font-family: 'Muli', sans-serif;
}

.txtProject a {
  word-break: break-all;
}

.txtProject h1 {
  font-size: 1.4rem;
}

.txtProject h2 {
  font-size: 1.1rem;
}

.txtProject h3 {
  font-size: 1rem;
}

.social-media-share-bar > * {
  margin: auto 5px;
}

.table-responsive {
  overflow-x: auto !important;
}

.oops h3 {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.opps {
  font-size: 14px;
  width: 460px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 45px;
  margin: 40px auto;
  overflow: hidden;
  border: 1px solid #b0afb5;
  font-family: 'Open Sans', sans-serif;
  color: #4f5365;
}

.opps-reminder {
  position: relative;
  top: -1px;
  padding: 9px 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  background: #000000;
}

.opps-info {
  margin-top: 26px;
  position: relative;
}

.opps-info:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.opps-brand {
  width: 45%;
  float: left;
}

.opps-brand img {
  max-width: 150px;
  margin-top: 2px;
}

.opps-ammount {
  width: 55%;
  float: right;
}

.opps-ammount h2 {
  font-size: 36px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 15px;
}

.opps-ammount h2 sup {
  font-size: 16px;
  position: relative;
  top: -2px;
}

.opps-ammount p {
  font-size: 10px;
  line-height: 14px;
}

.opps-reference {
  margin-top: 14px;
}

.opps h1 {
  font-size: 27px;
  color: #000000;
  text-align: center;
  margin-top: -1px;
  padding: 6px 0 7px;
  border: 1px solid #b0afb5;
  border-radius: 4px;
  background: #f8f9fa;
}

.opps-instructions {
  margin: 32px -45px 0;
  padding: 32px 45px 45px;
  border-top: 1px solid #b0afb5;
  background: #f8f9fa;
}

.opps ol {
  margin: 17px 0 0 16px;
}

.opps li + li {
  margin-top: 10px;
  color: #000000;
}

.opps a {
  color: #1155cc;
}

.opps-footnote {
  margin-top: 22px;
  padding: 22px 20 24px;
  color: #108f30;
  text-align: center;
  border: 1px solid #108f30;
  border-radius: 4px;
  background: #ffffff;
}

.oxxo-pay-btn {
  border-radius: 5px;
  height: 45px;
  background: rgba(218, 19, 19, 0.822) !important;
  border-color: gray !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.credit-card-btn {
  border-radius: 5px;
  border: 1px solid rgb(45 45 45 / 25%);
  background-color: rgb(45 45 45 / 25%);
  text-align: center;
  margin: auto;
  display: block;
  background: #092c66;
  font-weight: 700;
  padding: 0.6rem !important;
}

.paypal-pay-button,
.paypal-pay-button:hover {
  color: white !important;
  background: #169bd7 !important;
}
