.fondoVoluntariadoDTS {
  background-image:
    url("../../../../assets/img/Icons/background/flechabg.svg"),
    url("../../../../assets/img/Icons/background/corazonbg-01.svg");
  background-position-x: 88vw, -3.8vw;
  background-position-y: -1rem, 26rem;
  background-repeat: no-repeat;
  background-size: 11%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoVoluntariadoDTS {
    background-position-y: 2rem, 60rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoVoluntariadoDTS {
    background-position-x: 75vw, -3.8vw;
    background-position-y: 0rem, 60.5rem;
    background-size: 25%;
  }
}
