.title {
  font-size: 230%;
  line-height: 3.3rem;
  width: 80%;
  text-align: center;
  margin: auto;
}

.backgroundYellow {
  background-color: #F2C346;
  padding: 2% 0;
}
.txtAsterisco{
  color: white;
  font-size: 110%;
  text-align: center !important;
  display: flex;
  justify-content: center;
}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .title {
    font-size: 200%;
    /* line-height: 3.3rem; */
    width: 100%;
  }
  
}