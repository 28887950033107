.titleCard {
  font-size: 80%;
  color: #506ff4;
  font-style: bold;
  padding-bottom: 5%;
  padding-top: 9%;
  font-family: 'Noto Sans JP', sans-serif;
}
.fondo:hover .titleCard {
  color: #fff !important;
}
.subtitle {
  font-size: 200%;
  color: #092c66;
  font-style: bolder;
  padding-bottom: 3%;
  font-family: 'Noto Sans JP', sans-serif;
  padding-left: 0;
}
.fondo:hover .subtitle {
  color: #fff !important;
}
.txt {
  color: #6a7c8d;
  font-family: 'Muli', sans-serif;
}
.fondo:hover .txt {
  color: #fff !important;
}
.fondo {
  background-color: rgba(239, 240, 244, 0.685);
  border-top-left-radius: calc(2rem - 2px);
  border-top-right-radius: calc(2rem - 2px);
  margin-bottom: 0;
  padding-bottom: 0;
}
.fondo:hover {
  background-color: #092c66 !important;
  color: #fff !important;
}
.imgRadius {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
  width: 100%;
  height: 13em;
}
.card-thumbnail {
  border-radius: calc(2rem - 2px);
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: 50% 50%;
  margin-bottom: 4%;
}
.button {
  margin-top: 10%;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: 'Noto Sans JP', sans-serif;
}
.color {
  color: #05d39b !important;
}
.fondo:hover .color {
  color: #fff !important;
}
.logo {
  margin-top: 7%;
  width: 14%;
  margin-right: 1%;
  margin-left: 2%;
}
.margin {
  margin-left: 0%;
  padding-bottom: 5%;
  padding-top: 3%;
}
.titleGallerie {
  color: #092c66;
  font-family: 'Noto Sans JP', sans-serif;
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 1%;
  font-size: 250%;
}
.sinContenido {
  font-size: 1.5rem;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 2px;
  margin-left: 27%;
  margin-top: 15% !important;
  margin-bottom: 15% !important;

  animation: background-move 10s infinite;

  background: url(./../../OurProjectsSection/ProjectsConsiderationsSection/img/2.jpg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sinContenido_perfil {
  font-size: 1.5rem;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 2px;

  margin: 15% auto !important;

  animation: background-move 10s infinite;

  background: url(./../../OurProjectsSection/ProjectsConsiderationsSection/img/2.jpg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes background-move {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 5% 10%;
  }

  50% {
    background-position: 15% 15%;
  }

  75% {
    background-position: 10% 5%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1990px) {
  .sinContenido {
    margin-bottom: 25% !important;
    letter-spacing: 4px;
    margin-left: 30%;
  }
}
@media only screen and (max-width: 1680px) {
  .logo {
    margin-top: 9%;
    width: 12%;
    margin-right: 1%;
    margin-left: 2%;
  }
  .titleCard {
    font-size: 80%;
    padding-bottom: 5%;
  }
  .subtitle {
    font-size: 180%;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1020px) and (max-width: 1024px) {
  .sinContenido {
    margin-left: 20%;
    margin-bottom: 20% !important;
  }
}

@media only screen and (min-width: 760px) and (max-width: 762px) {
  .sinContenido {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .margin {
    margin: auto;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .card-thumbnail {
    margin-bottom: 10%;
  }
  .margin {
    margin-left: 0% !important;
  }

  .sinContenido {
    margin-left: 2%;
  }
}
