.contact-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.contact-container > .fondoContacto {
  flex: 1;
}

.input {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 95%;
  padding: 1.4rem !important;
  border-radius: 17px;
  font-weight: 600;
}

.input::placeholder {
  opacity: 0.7;
}

.title {
  font-size: 75%;
  font-weight: 900;
  color: #506ff4;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.48;
  letter-spacing: 1.4px;
  text-align: left;
  padding-top: 7%;
  padding-bottom: 2%;
  font-family: 'Noto Sans JP';
 }
.label {
  color:#6A7C8D;
  font-style: bold;
  font-weight: 800;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitle {
  font-size: 240%;
  font-weight: 900;
  color: #092C66;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1.1px;
  text-align: left;
  margin-bottom: 6%;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitleLogin {
  font-size: 290%;
  color: #092C66;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 2%;
  font-family: 'Noto Sans JP', sans-serif;
}
.logo {
  margin-top:2%;
  width: 2%;
  margin-right: 1%;
  margin-left: 1%;
}
.logo1 {
  margin-top:2%;
  width: 4%;
  margin-right: 2%;
  margin-left: 2%;
}

.buttonContact{
  outline:none !important;
  height: 4em !important;
  text-align: center !important;
  width: 10em !important;
  border-radius:35px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 3px solid #05D39B !important;
  color:#05D39B !important;
  letter-spacing:1px !important;
  font-size: 100% !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  box-shadow: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin: 4% 0;
  text-transform: uppercase;
  font-weight: 700;
}
.buttonContact:disabled {
  cursor: not-allowed !important;
  background: #6A7C8D !important;
  border: 3px solid #6A7C8D !important ;
  color: white !important;
}
.buttonContact:hover:enabled {
  color: #fff !important;
  background: #05d39b !important;
}
.buttonLogIn{
  outline:none !important;
  height: 4em !important;
  text-align: center !important;
  width: 10em !important;
  border-radius:35px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 3px solid #506ff4 !important;
  color:#506ff4 !important;
  letter-spacing:1px !important;
  font-size: 100% !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  box-shadow: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  margin-top: 8%;
  margin-bottom: 3%;
  margin-left: 40% !important;
  text-transform: uppercase;
}
.buttonLogIn:hover {
  color:white !important;
    background: #506ff4 !important;
}

.fondoContacto {
  background-image:
    url("../../../../assets/img/Icons/background/circulong-01.svg"),
    url("../../../../assets/img/Icons/background/plus_bg-01.svg"),
    url("../../../../assets/img/Icons/background/flechabg.svg");
  background-position-x: 93vw, -3.8vw, 90vw;
  background-position-y: 0rem, 19rem, 28rem;
  background-repeat: no-repeat;
  background-size: 10.5%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoContacto {
    background-position-x: 93vw, -9vw, 89vw;
    background-position-y: 0rem, 19rem, 38rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoContacto {
    background-position-x: 85vw, -9vw, 100vw;
    background-position-y: 1rem, 45rem, 45rem;
    background-size: 25%;
  }
}


/*************************************************/
/********** Versión mobile  **********************/
/*************************************************/
@media (max-width: 767.98px) {
  .buttonContact{
    display: block;
    margin: auto;
  }
}
@media only screen and (min-width: 1700px ) and (max-width: 1990px ) {
  .buttonLogIn{
    margin-bottom: 40%;
  }
}
@media only screen and (min-width: 1365px ) and (max-width: 1366px ) {
  .subtitle {
    margin-bottom: 4%;
    font-size: 220%;
  }
  .title {
    padding-top: .5%;
    font-size: 75%;
    padding-top: 0%;
  }
  .buttonContact{
    margin-top: 2%;
  }
  .label {
    margin-bottom: 0%;
    font-size: 85%;
  }
  .input {
    padding-top: 0%;
    padding-bottom: 0%;
  }

}
@media only screen and (min-width: 1019px ) and (max-width: 1020px ) {
  .buttonLogIn{
    margin-left: 36% !important;
    margin-bottom: 40% !important;
  }
}
/* @media only screen and (min-width: 1023px ) and (max-width: 1024px ) {

  .buttonLogIn{
    margin-bottom: 70%;
  }
} */
@media only screen and (min-width: 1016px ) and (max-width: 1022px ) {

  .buttonLogIn{
    margin-bottom: 30%;
  }
}
@media only screen and (min-width: 1008px ) and (max-width: 1015px ) {
  .subtitle {
    margin-bottom: 5%;
  }
  .title {
    padding-top: 0%;
  }
  .buttonContact{
    margin-top: 4%;
  }
  .buttonLogIn{
    margin-bottom: 120%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .subtitleLogin {
    margin-top: 0% !important;
    font-family: 'Noto Sans JP', sans-serif;
  }
  .buttonLogIn{
    margin-bottom: 30%;
  }
 }

 @media only screen and (min-width: 767px ) and (max-width: 768px ) {
  .subtitle {
    margin-bottom: 5%;
  }
  .title {
    padding-top: 4%;
  }
  .buttonContact{
    margin-top: 4%;
  }
  .buttonLogIn{
    /* margin-bottom: 40%; */
    margin-left: 30% !important;
  }
}
@media only screen and (min-width: 752px ) and (max-width: 766px ) {
  .subtitle {
    margin-bottom: 5%;
  }
  .title {
    padding-top: 0%;
  }
  .buttonContact{
    margin-top: 4%;
  }
}
@media only screen and (min-width: 760px ) and (max-width: 762px ) {
  .buttonLogIn{
    margin-bottom: 20%;
    margin-left: 34% !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 599px) {
  .subtitleLogin {
    margin-top: 0%;
    padding-top: 0% !important;
  }
  .buttonLogIn{
    margin-bottom: 20%;
    margin-left: 25% !important;
  }
 }
