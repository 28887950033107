.titles {
  font-size: 105%;
  text-align: center;
}
.pretitles{
  font-size: 255%;
}

.fondoPlusValues {
  background-image: url("../../../../assets/img/Icons/background/flechabg.svg"), url("../../../../assets/img/Icons/background/plus_bg-01.svg");
  background-position-x: -5vw, 90vw;
  background-position-y: 34rem, 0rem;
  background-repeat: no-repeat, no-repeat;
  background-size: 12%;
  padding: 4% 0;
}

/*************************************************/
/**** Versión mobile******************************/
/*************************************************/

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoPlusValues {
    background-position-y: 34rem, 0rem;
    background-size: 15%;
    padding-bottom: 11%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoPlusValues {
    background-position-x: -5vw, 84vw;
    background-position-y: 60rem, 0rem;
    background-size: 25%;
  }
}