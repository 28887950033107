.title {
  font-size: 90%;
  color: #506FF4;
  font-style: bold; 
  padding-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}

.seccionCounter {
  width: 100%;
}
.subtitle{
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  margin-bottom: 2%;
  font-family: 'Noto Sans JP', sans-serif;
}

.fondoCounter {
  background-image: url("../../../assets/img/Icons/background/circulong-01.svg");
  background-position-x: 92vw;
  background-position-y: 2rem;
  background-repeat: no-repeat;
  background-size: 12%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoCounter {
    background-size: 14%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoCounter {
    background-position-x: 87vw;
    background-size: 25%;
  }
}
