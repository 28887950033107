:root {
  --navbar-height: 60px;
}

.slick-prev:before, .slick-next:before {
  color: #05d39b;
}

section.navbar-buttons {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  background: rgb(235, 233, 233);
  z-index: 100;
  align-items: center;
  height: 62px;
  top: 60px;
  align-items: center !important;
}

section.navbar-buttons button {
  margin-top: 0 !important;
  height: 50px !important;
  margin-top: 6px !important;
  line-height: unset !important;
  font-size: 15px !important;
}

header.nav-fs {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 0 50px;
  width: 100%;
  z-index: 200;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f4f4f4 !important;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
}

header.nav-fs .logo {
  line-height: var(--navbar-height);
  float: left;
}

header.nav-fs .logo img {
  width: 230px;
}

header.nav-fs .flag-icon-mobile {
  display: none;
}

header.nav-fs .language ul.sub-menu,
header.nav-fs .language ul.sub-menu li {
  width: 80px !important;
}

header.nav-fs nav {
  float: left;
}

header.nav-fs nav ul {
  margin: 0;
  padding: 0;
  display: flex;
}

header.nav-fs nav > ul > li > a {
  font-size: 12px !important;
}

header.nav-fs nav ul .float-right {
  float: right;
}

header.nav-fs nav ul .btn-login {
  outline: none;
  text-align: center;
  border-radius: 30px;
  background: transparent;
  border: 3px solid #506ff4;
  color: #506ff4;
  fill: #506ff4;
  cursor: pointer;
  transition: all 0.25s ease;
  box-shadow: 0;
  text-transform: uppercase;
  font-weight: 900;
  padding: 8px 18px;
}
header.nav-fs nav ul .btn-login:hover {
  color: white;
  fill: white !important;
  background: #506ff4;
}
header.nav-fs nav ul .btn-login:hover .btn-user path {
  stroke: white !important;
}
header.nav-fs nav ul .btn {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
header.nav-fs nav ul .btn-user {
  width: 13px;
  margin-right: 5px;
  margin-bottom: 3px;
}

header.nav-fs nav ul .authenticated > a {
  color: #506ff4 !important;
}

header.nav-fs nav ul .authenticated li {
  width: 210px !important;
}

header.nav-fs nav ul li {
  list-style: none;
  position: relative;
}

header.nav-fs nav ul li.link-hidden {
  display: none;
}

header.nav-fs nav ul li ul {
  position: absolute;
  left: 0;
  box-shadow: inset 0 4px 4px 0px rgb(229, 229, 229);
  background-color: #f4f4f4;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: none;
}
header.nav-fs nav ul li ul li {
  display: block;
  width: 235px;
}
header.nav-fs nav ul li:hover ul {
  display: block;
}
/* header.nav-fs nav ul li.dropdown-open ul {
  display: block;
} */

header.nav-fs .sub-menu a {
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
}

header.nav-fs .sub-menu a > .arrow-link {
  visibility: hidden;
  width: 16.8px;
  margin-right: 14px;
}

header.nav-fs .sub-menu a:hover > .arrow-link {
  visibility: visible;
}

header.nav-fs .sub-menu a:active {
  background-color: #e8e5e5;
}

header.nav-fs nav ul li a {
  height: var(--navbar-height);
  line-height: var(--navbar-height);
  padding: 0 20px;
  color: #6a7c8d;
  text-decoration: none;
  display: block;
  font-weight: 600;
  font-size: 11.5px;
}

header.nav-fs nav > ul > li > a:hover {
  color: #05d39b;
}

header.nav-fs .menu-toggle {
  display: none;
}

header.nav-fs .arrow-toggle-dropdown {
  display: none;
}

@media (min-width: 1200px) {
  header.nav-fs nav > ul > li > a {
    font-size: 13px !important;
  }
}

/* Medium devices */
@media (max-width: 1250px) and (min-width: 992px) {
  header.nav-fs nav > ul > li a {
    font-size: 10px !important;
  }
  header.nav-fs nav ul .btn-login {
    padding: 6px 10px !important;
  }
  header.nav-fs {
    padding: 0 40px;
  }
}
@media (max-width: 1102px) and (min-width: 992px) {
  header.nav-fs .logo img {
    width: 135px;
  }
}

/* Small and extra-small devices */
@media (max-width: 991px) {
  section.navbar-buttons button {
    font-size: 13px !important;
  }

  header.nav-fs .menu-toggle {
    background-color: #e4e8f0;
    border-radius: 50%;
    line-height: var(--navbar-height);
    font-size: 22px;
    cursor: pointer;
  }

  header.nav-fs .menu-toggle > i {
    color: #224175 !important;
  }

  header.nav-fs {
    padding: 0 20px;
  }
  header.nav-fs .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  header.nav-fs .flag-icon-mobile {
    display: block;
    float: right;
    margin-left: 20px;
  }

  header.nav-fs .menu-toggle {
    height: 35px;
    width: 35px;
    margin-top: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  header.nav-fs nav {
    position: absolute;
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    background: #05d39b;
    top: var(--navbar-height);
    left: -100%;
    transition: 0.5s;
  }
  header.nav-fs nav.active {
    left: 0;
  }

  header.nav-fs .nav-fs-collapse {
    overflow-y: auto;
  }

  header.nav-fs nav ul {
    display: block;
  }
  header.nav-fs nav ul li > a {
    color: #ffff;
    border-top: 0.8px solid #0db689a2;
    border-bottom: 0.8px solid #0db689a2;
  }
  header.nav-fs nav ul.sub-menu a {
    border-top: none;
    border-bottom: none;
  }
  header.nav-fs nav ul li > a:hover {
    color: #ffff;
  }
  header.nav-fs nav ul li ul {
    position: relative;
    background: #05d39b;
    box-shadow: none;
  }
  header.nav-fs nav ul li.dropdown-open ul.sub-menu > li a {
    padding-left: 50px;
  }
  header.nav-fs nav ul li ul li {
    width: 100%;
  }
  header.nav-fs nav ul li.link-hidden {
    display: block;
  }
  header.nav-fs .sub-menu a:active {
    background-color: #0db689a2;
  }

  header.nav-fs nav ul li:hover ul {
    display: none;
  }

  header.nav-fs nav ul li.dropdown-open ul {
    display: block !important;
  }

  header.nav-fs .dropdown-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header.nav-fs .arrow-toggle-dropdown {
    display: inline-block;
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }

  header.nav-fs .dropdown-open .arrow-toggle-dropdown {
    transform: rotate(270deg);
  }
}
