.txtIcons {
  color: #6A7C8D;
  font-family: 'Muli', sans-serif;
  text-align: center !important;
  font-size: 100%;
}
.txt_justify {
  margin-top: 3.5%;
  text-align: justify;
}

.fondoProjects {
  background-image: url("../../../../assets/img/Icons/background/plus_bg-01.svg"), url("../../../../assets/img/Icons/background/circulong-01.svg");
  background-position-x: -3.8vw, 93vw;
  background-position-y: 13rem, 24rem;
  background-repeat: no-repeat, no-repeat;
  background-size: 12%;
  padding: 2% 0;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoProjects {
    background-position-y: 38rem, 22rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoProjects {
    background-position-x: -8vw, 93vw;
    background-size: 25%;
  }
}
