.logoSolidaria {
  width: 40%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .logoSolidaria {
    width: 55%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .logoSolidaria {
    width: 85%;
  }
}
