.image-gallery {
  height: 500px;
}

.image-gallery-image {
  background: #202020;
}

.image-gallery-slide img {
  display: block;
  margin: auto;
  width: auto !important;
  height: 500px;
  overflow: hidden;
  object-position: center center;
}