.iconVoluntario {
  width: 70%;
  margin-left: 13%;
}
.txtVoluntario {
  color: #6A7C8D;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 120%;
}

.buttonContainer {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7%;
}

@media only screen and (max-width: 576px) {
  .buttonContainer {
    margin: 8% auto;
    margin-bottom: 14%;
  }
  .txtVoluntario{
    text-align: center;
  }
}
