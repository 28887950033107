.logo{
  width: 20% ;
}

.txtC {
  color: #EFF0F4;
  font-size: 200%;
  margin-top: 4%;
  font-family: 'Noto Sans JP', sans-serif;
}
.backgroundDonar {
  background-image: url("./img/donar-home.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .logo {
    width: 50%;
  }
  .txtC {
    width: 70%;
  }
}  

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .logo {
    width: 75%;
  }
  .txtC {
    width: unset;
    font-size: 180%;
  }
}
