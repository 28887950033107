.counter-container {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0);
  color: #05d39b;
  align-items: center;
  margin-bottom: 9%;
}
.numbers {
  font-size: 440%;
  margin-top: 0%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
}
.subtitle-counter {
  color: #6a7c8d;
  font-size: 20px;
  margin-top: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}

/* Versión mobile de Contador */

@media only screen and (min-width: 989px) and (max-width: 1200px) {
  .counter-container {
    align-items: center;
    font-size: 200%;
    margin-bottom: 10%;
  }
  .numbers {
    font-size: 200% !important;
  }
  .subtitle-counter {
    font-size: 60%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 990px) {
  .counter-container {
    margin: 5% 0;
    padding: 0 !important;
  }
  .numbers {
    font-size: 250%;
  }
}

@media only screen and (min-width: 331px) and (max-width: 500px) {
  .counter-container {
    margin: 5% 0;
  }
  .numbers {
    font-size: 300% !important;
  }
  .subtitle-counter {
    font-size: 110%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 330px) {
  .counter-container {
    margin: 4% 0;
  }
  .numbers {
    font-size: 200% !important;
  }
  .subtitle-counter {
    font-size: 15px;
  }
}
