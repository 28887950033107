.buttonAS{
  outline:none !important;
  height: 4em !important;
  text-align: center !important;
  width: 13em !important;
  border-radius:35px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 3px solid #05D39B !important;
  color:#05D39B !important;
  letter-spacing:1px !important;
  font-size: 120% !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  box-shadow: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 600;
}
.buttonAS:hover {
  color:white !important;
  background: #05D39B !important;
}



