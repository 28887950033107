.fondoRojo {
  background-color: #F55D50;
  padding: 4% 0;
 }
 .titleMotivation {
  font-size: 105%;
  color: #EFF0F4;
  font-style: bold;
  margin-top: 7%; 
  margin-bottom: 0;
  font-family: 'Noto Sans JP', sans-serif;
}
.subtitleMotivation {
  font-size: 255%;
  color: #092C66;
  font-style: bolder;
  font-family: 'Noto Sans JP', sans-serif;
}
.txtMotivation {
  color: #EFF0F4;
  font-family: 'Muli', sans-serif;
  text-align: center;
}

/*************************************************/
/********** Versión mobile de motivation**********/
/*************************************************/




@media only screen and ( min-width: 991px )  and (max-width: 1000px ) {
  .titleMotivation {
    margin-top: 15%;
    margin-bottom: 5%;
  }
  .subtitleMotivation {
    font-size: 280%;
  }
  
}

@media only screen and (min-width: 500px ) and (max-width: 990px ) {
  .titleMotivation {
    margin-top: 6% !important;
    margin-bottom: 5%;
  }
  .subtitleMotivation {
    font-size: 280%;
  }
  
}

@media only screen and (min-width: 331px ) and (max-width: 500px ) {
  .titleMotivation {
    margin-top: 12% !important;
    margin-bottom: 5%;
    font-size: 80%;
  }
  .subtitleMotivation {
    font-size: 280%;
  }
  
}

@media only screen and (min-width: 0px ) and (max-width: 330px ) {
  .titleMotivation {
    margin-top: 15%;
    margin-bottom: 5%;
  }
  .subtitleMotivation {
    font-size: 280%;
  }
  
}
