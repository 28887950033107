.backgroundDonar {
  background-image: url("../../../assets/img/Home/donar-home.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.logoSolidaria{
  width: 20%;
  margin-bottom: 4%;
}
.txt {
  color: #EFF0F4;
  font-size: 210%;
  letter-spacing: 1px;
  font-family: 'Noto Sans JP', sans-serif;
}

/*************************************************/
/********** Versión mobile de Donación************/
/*************************************************/
@media (min-width: 1921px) {
  .logoSolidaria {
    width: 9%;
    margin-bottom: 1%;
  }
}
@media only screen and (min-width: 812px ) and (max-width: 990px ) {
  .logoSolidaria{
    width: 45%;
  }
  .txt {
    font-size: 160%;
    letter-spacing: 1px;
    margin-top: 3%;
  }
}
@media only screen and (min-width: 768px ) and (max-width: 811px ) {
  .logoSolidaria{
    width: 45%;
  }
  .txt {
    font-size: 160%;
    letter-spacing: 1px;
    margin-top: 3%;
  }
}


@media only screen and (min-width: 500px ) and (max-width: 767px ) {
  .logoSolidaria{
    width: 55%;
    margin-bottom: 10%;
  }
  .txt {
    font-size: 160%;
    letter-spacing: 1px;
    margin-top: 3%;
  }
}


@media only screen and (min-width: 331px ) and (max-width: 500px ) {
  .logoSolidaria{
    width: 55%;
    margin-bottom: 15%;
  }
  .txt {
    font-size: 130%;
    letter-spacing: 1px;
    margin-top: 3%;
  }
}

@media only screen and (min-width: 0px ) and (max-width: 330px ) {
  .logoSolidaria{
    width: 55%;
  }
  .txt {
    font-size: 150%;
    letter-spacing: 1px;
    margin-top: 3%;
  }
}



