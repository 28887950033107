.backgroundVolunteerYellow {
  padding-bottom: 3%;
  padding-top: 6%;
  background-color: #F2C346;
}
.title {
  font-size: 105%;
  color: #506FF4;
  font-style: bold;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
}
.subtitle{
  font-size: 260%;
  color: #092C66;
  font-style: bold;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
}

/*************************************************/
/********* Versión mobile de Volunteer ***********/
/*************************************************/
@media only screen and (max-width: 768px) {
  .backgroundVolunteerYellow {
    padding: 10% 0;
  }
  .title {
    padding-top: 5%;
  }
  .subtitle {
    font-size: 200%;
  }
}

@media only screen and (min-width: 0px ) and (max-width: 320px ) {
  .subtitle{
    align-self: center !important;
    font-size: 180%;
  }
}
