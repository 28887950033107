.iconVoluntario {
  width: 100%;
}
.subtitleVoluntario {
  font-size: 180%;
  color: #6A7C8D;
  font-style: bolder;
  text-transform: uppercase;
  font-family: 'Noto Sans JP', sans-serif;
}
.txtVoluntario {
  color: #6A7C8D;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 120%;
}

@media only screen and (max-width: 576px) {
  .subtitleVoluntario {
    text-align: center;
    font-size: 160%;
  }
  .txtVoluntario{
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .iconVoluntario {
    width: 40%;
    margin-bottom: 7%;
  }
}