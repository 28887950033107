.card {
  background-color: #EFF0F4;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 8.5%;
  height: 100%;
}
.image {
  display: block;
  margin: 10% auto;
  border-radius: 70%;
  width: 120px;
  height: 120px;
}
.titleCard {
  font-size: 120%;
  color: #506ff4;
  font-style: normal;
  margin-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
}
.txtCard {
  color: #6A7C8D;
  padding-bottom: 2%;
  font-family: 'Muli', sans-serif;
  font-style: normal; 
  text-align: center;
  font-size: 90%;
  font-weight: normal;
  margin-right: 10%;
  margin-left: 10%;
}


/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .titleCard{
    font-size: 100%
  }
  .image{
    width: 80px;
    height: 80px;
  }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .titleCard{
    font-size: 120%;
  }
  .image{
    width: 120px;
    height: 120px;
  }
  .txtCard{
    padding-bottom: 4.8%;
  }
}