.buttonValues{
  outline:none !important;
  height: 4em !important;
  text-align: center !important;
  width: 17em !important;
  border-radius:40px !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 2px solid #fff !important;
  color:#fff !important;
  letter-spacing:1px !important;
  text-shadow:0 !important;
  font-size: 115% !important;
  font-style: bolder !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5%;
}

.buttonValues:hover {
  color:#F55D50 !important;
  background: #fff !important;
}
.marginButton {
  margin-left:35%;
}

.centerButton {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.3%;
}

/*************************************************/
/**** Versión mobile de OurValues Button *********/
/*************************************************/
@media only screen and (max-width: 1680px ) {
  .logoSolidaria {
    margin-top:15%;
  }
}

@media only screen and (min-width: 500px ) and (max-width: 1000px ) {
  .marginButton {
    margin-left:20%;
    margin-bottom: 20%;
  }
}
@media only screen and (min-width: 413px ) and (max-width: 414px ) {
  .marginButton {
    margin-left:8% !important;
    margin-bottom: 20%;
  }
}
@media only screen and (min-width: 331px ) and (max-width: 500px ) {
  .marginButton {
    margin-left:1%;
    margin-bottom: 20%;
  }
}
@media only screen and (min-width: 0px ) and (max-width: 330px ) {
  .marginButton {
    margin-left:0%;
    margin-bottom: 20%;
  }
  .buttonValues{
    width: 13em !important;
    font-size: 130% !important;
  }
}



