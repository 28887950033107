.active_link {
  background-color: #3fd39c !important;
  color: #ffffff !important;
}

.link {
  /* background-color: #e4e8f0 !important; */
  background-color: #ffffff !important;
  color: #6e707e !important;
}

.active_link,
.link {
  font-weight: 700;
  border-radius: 50% !important;
  border: 0;
  height: 40px;
  width: 40px;
  margin: auto 5px;
}
