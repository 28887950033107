.mission {
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  font-family: 'Noto Sans JP', sans-serif;
}

.vission {
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  font-family: 'Noto Sans JP', sans-serif;
}
.fondoAbout {
  background-image: url("../../../assets/img/Icons/background/plus_bg-01.svg"), url("../../../assets/img/Icons/background/flechabg.svg");
  background-position-x: -3.8vw, 90vw;
  background-position-y: 14rem, 0rem;
  background-repeat: no-repeat, no-repeat;
  background-size: 12%;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoAbout {
    background-position-y: 15rem, 0rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoAbout {
    background-position-y: 20rem, 0rem;
    background-size: 25%;
  }
}
