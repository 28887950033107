.breadcrumb {
  border-radius: 42px;
  background-color: #f4f4f7;
  font-family: 'Noto Sans JP';
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 90%;
  margin-top: 4%;
  margin-bottom: -1%;
  width: 66%;
}

.breadcrumb a,
.breadcrumb li {
  color: #636875 !important;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .breadcrumb {
    font-size: 82%;
    letter-spacing: 0.06em;
    margin-top: 5%;
    width: 100%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .breadcrumb {
    font-size: 80%;
    border-radius: 30px;
  }
}
