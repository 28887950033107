.backgroundColorVideo {
  background-color: #05D39B;
}
.pretitle {
  color: #EFF0F4 !important;
  font-style: bold; 
  font-family: 'Noto Sans JP', sans-serif;
}
.title{
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  margin-bottom: 7%;
  font-family: 'Noto Sans JP', sans-serif;
}
.logoSolidaria {
  width: 30%;
  height: 1%;
  margin-top: 2%;
  margin-bottom: 4%;
}
.video {
  margin-left: 10%;
  margin-bottom: 0 !important;
}
.margin {
  padding-top: 4%;
  padding-bottom: 0 !important;
}
.roundVideo{
  width: 100%;
  height: auto;
  padding: 0%;
}
@media only screen and (min-width: 0px ) and (max-width: 500px ) {
  .video {
    margin-left: 0;
    margin-bottom: 0 !important;
  }
  .title {
    font-size: 180%;
  }
  .logoSolidaria {
    width: 50%;
  }
  .logoSolidaria {
    width: 90%;
    margin-bottom: 8%;
  }
  .margin {
    padding: 4% 0;
  }
}
@media only screen and (min-width: 500px ) and (max-width: 1000px ) {
  .video {
    margin-left: 0;
    margin-bottom: 0 !important;
  }
  .title {
    font-size: 240%;
  }
  .logoSolidaria {
    width: 55%;
    margin-bottom: 7%;
  }
  .margin {
    padding: 4% 0;
  }
}

