.tag-container {
  color: #5f6472;
  width: 180px;
  padding: 1%;
}
.tag-icon {
  font-size: 14px;
}
.tag {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 75%;
  margin-left: 0.5%;
}
@media only screen and (min-width: 360px) and (max-width: 640px) {
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 640px) and (max-width: 360px) {
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
@media only scree and (min-width: 372px) and (max-width: 812px) {
  .tag-container {
    display: flex;
    flex-wrap: wrap;
  }
  .tag-icon {
    margin-right: 1%;
  }
}
@media only screen and (min-width: 812px) and (max-width: 375px) {
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
