.background-projects {
  background-image: url("../../../assets/img/Icons/background/plus_bg-01.svg");
  background-position-x: 92vw;
  background-position-y: -1rem;
  background-repeat: no-repeat, no-repeat;
  background-size: 12%;
}

@media (max-width: 991.98px) {
  .background-projects {
    background-position-y: 0rem;
    background-size: 15%;
  }
}

@media (max-width: 767.98px) {
  .background-projects {
    background-position-y: 0rem;
    background-size: 25%;
  }
}
