.container__img-profile {
  display: block;
  margin: 10px auto;
  width: 190px;
  height: 190px;
  /* margin: 20px; */
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  /* background-color: #2d2d2d; */
}

.img-profile {
  position: absolute;
  margin: auto; 
  height: 100%;
  width: auto;
  /* For the following settings we set 100%, but it can be higher if needed */
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
}
