.filters {
  display: flex;
  justify-content: center;
}

.filters input,
.filters select {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 85%;
  border-radius: 10px;
}

.button,
.button:hover {
  background-color: #3fd39c;
  color: #ffffff;
}

.button:hover {
  background-color: #39c391;
}
