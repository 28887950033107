.iconVerifiedPrograms {
  margin-top: 4px !important;
  margin-bottom: 3px;
  width: 2%;
  margin-right: 3.5%;
  float: left;
}
@media only screen and (min-width: 315px ) and (max-width: 640px){
  .iconVerifiedPrograms {
    margin-top: 4px !important;
    margin-bottom: 3px;
    width: 5%;
    margin-right: 3.5%;
    float: left;
  }
}
@media only screen and (min-width: 640px ){
  .iconVerifiedPrograms {
    margin-top: 4px !important;
    margin-bottom: 3px;
    width: 3%;
    margin-right: 3.5%;
    float: left;
  }
}
@media only screen and (min-width: 1024px ){
  .iconVerifiedPrograms {
    margin-top: 4px !important;
    margin-bottom: 3px;
    width: 2%;
    margin-right: 3.5%;
    float: left;
  }
}