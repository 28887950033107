.carousel {
  height: 88vh;
}

.caption {
  position: absolute !important;
  bottom: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.h100 {
  height: 100%;
}

.carousel__caption {
  z-index: 10;
  bottom: 0;
  right: 0;
  width: 50%;
  color: #6a7c8d;
  text-align: left;
  padding: 27px 50px !important;
  position: absolute;
  background-color: #eff0f4;
  border-top-left-radius: 35px;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.5px;
  height: 150px;
  display: flex !important;
  justify-content: space-between;
  flex-direction: row;
  /* font-weight: 500; */
  /* min-height: 9rem; */
  /* border-top-left-radius: 35px; */
  /* display: flex;
  align-items: center; */
}

.carousel__caption-content {
  width: 90%;
}

.carousel_arrow {
  width: 6%;
  fill: #6a7c8d;
}


.carousel__caption h3 {
  font-size: 135%;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  font-weight: 600;
}

.carousel__caption p {
  font-size: 94%;
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
}

.carousel__caption:hover {
  color: #ffff;
  background-color: #05D39B;
}

.carousel__caption:hover > .carousel_arrow {
  fill: #ffff;
}

.carousel-item {
  height: 88vh !important;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}



/* Small devices (landscape phones, less than 769px) */
@media (max-width: 768px) {
  .carousel__caption{
    padding: 20px 33px !important;
    font-size: 14px;
    width: 100%;
    height: 160px;
  }
  .carousel__caption-content {
    width: 85%;
  }
  .carousel__caption > h3 {
    margin-top: 2%;
  }
  .carousel_arrow {
    width: 10%;
  }
}

/* Medium devices */
@media (min-width: 767.95px) and (max-width: 991.98px) {
  .carousel_arrow {
    width: 6%;
  }
  .carousel__caption {
    padding: 29px 50px !important;
  }
}

/* Extra large devices (more than 1400px) */
@media (min-width: 1400px) {
  .carousel, .carousel-item {
    height: 85vh !important;
  }
  .carousel_arrow {
    max-width: 4%;
  }
}