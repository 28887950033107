.fondoCorazon {
  background-image: url("../../../../assets/img/Icons/background/corazonbg-01.svg");
  background-position-x: -3.8vw;
  background-position-y: 57rem;
  margin-bottom: 4% !important;
}

.fondoFlechaCirculo {
  background-image:
    url("../../../../assets/img/Icons/background/flechabg.svg"),
    url("../../../../assets/img/Icons/background/circulong-01.svg");
  background-position-x: -3.8vw, 92vw;
  background-position-y: 25rem, 65rem;
}

.fondoCorazon,
.fondoFlechaCirculo {
  background-repeat: no-repeat;
  background-size: 12%;
}
.title{
  text-align: left;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoCorazon,
  .fondoFlechaCirculo {
    background-size: 15%;
  }
  .fondoCorazon {
    background-position-y: 83rem;
  }
  .fondoFlechaCirculo {
    background-position-y: 90rem, 157rem;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoCorazon,
  .fondoFlechaCirculo {
    background-size: 25%;
  }
  .fondoCorazon {
    background-position-y: 68rem;
  }
  .fondoFlechaCirculo {
    background-position-x: -3.8vw, 85vw;
    background-position-y: 77rem, 180rem;
  }
}

/* Extra small devices (portrait phones, less than 331px) */
@media (max-width: 330.98px) {
  .fondoCorazon {
    background-position-y: 85rem;
  }
  .fondoFlechaCirculo {
    background-position-y: 85rem, 180rem;
  }
}
