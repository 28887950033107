.footer {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  background-color: rgb(34, 48, 71);
  margin-top:auto !important;
  display: flex;
  flex-direction: row;
 }
.footer :nth-child(1){order: 1;}
.footer :nth-child(2){order:2;}
.footer :nth-child(3){order:3;}

.place{
  margin-left: 45px;
  margin-right: -50px;
}
.social-link {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 3.3rem !important;
  font-size: 1.5rem;
  background-color: #05D39B;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
  outline:none !important;
  background: rgba(255, 255, 255, 0) !important;
  border: 2px solid #05D39B !important;
  text-shadow:0 !important;
  cursor: pointer !important;
  transition: all 0.25s ease !important;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #05D39B;
}
.social-link:hover {
  background-color: #05D39B!important;
  fill: white;
  text-decoration: none;
 }
 .icon {
  line-height: inherit; 
 }
 .logoSofttek {
  width: 64%;
  margin-top: 6%;
 }
 .copy {
  color: rgba(205, 208, 216);
  font-size: 80%;
  margin-top: 10%;
  margin-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
  visibility: visible;
  display: block;
  margin-right: 28%;
 }
 .font {
   color:rgba(205, 208, 216);
   font-size: 80%;
   font-family: 'Noto Sans JP', sans-serif;
   font-weight: 500;
   font-style: normal;
   font-stretch: normal;
   letter-spacing: 1.9px !important;
 }
 .divSocial{
   padding-right: 11%;
 }
 .divFont{
   margin-left: 1%;
 }
 .notice{
  font-family: 'Noto Sans JP', sans-serif;
  color:rgba(205, 208, 216);
  font-size: 80%;
 }
 .terms{
  font-family: 'Noto Sans JP', sans-serif;
  color:rgba(205, 208, 216);
  font-size: 80%;
 }
  .terms:hover {
    color:rgba(205, 208, 216);
  }
 .social-icon {
   width: 55%;
   align-self: center;
   display: flex;
   align-items: center;
   justify-content: center;
 }
.social-media{
  width: 55%;
}



 /*************************************************/
 /*********** Versión mobile de Footer ************/
 /*************************************************/


/* Large devices (desktops, less than 1400px)*/
@media (min-width: 1399.98px) {
  .footer {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
   }
  .font {
   font-size: 77%;
   line-height: 1.5;
   padding-top: 1.8%;
   }
   .logoSofttek {
    width: 57%;
   }
 .copy {
  margin-top: 10%;
  margin-bottom: 1%;
  margin-right: 20%;
 }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) { 

.footer :nth-child(1){order: 4;}
.footer :nth-child(2){order:3;}
.footer :nth-child(3){order:2;}
.footer :nth-child(4){order:1}
  
.copy {
  font-size: 90%;
  font-family: 'Noto Sans JP',sans-serif;
  letter-spacing: .5px;
  display: flex;
  margin-top: 2%;
  margin-right: 37%;
  justify-content: flex-end;
}
   .social-link {
    height: 3rem;
    width: 3rem;
    line-height: 3.3rem !important;
    font-size: .5rem;
  }
  
   .logoSofttek {
    width: 25%;
    height: 40%;
    margin-left: 4%;
    margin-top: 9%;
    margin-bottom: 9%;
  }
   .iconsSocial{
     margin-top: 2% !important;
     margin-left: 15%;
   }
   .font {
    font-size: 100%;
    margin-left: 13%;
    padding: 2%;
   }
    .terms{
     font-family: 'Noto Sans JP', sans-serif;
     color:rgba(205, 208, 216);
     font-size: 90%;
    }
    .place{
      padding: 2%;
      margin: 0;
    }
    .mobile{
      margin-left: 30%;
      margin-bottom: 4%;
    }
  }
  

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .mobile{
    margin-left: 30%;
    margin-bottom: 4%;
  }
}


/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

.footer :nth-child(1){order: 4;}
.footer :nth-child(2){order:3;}
.footer :nth-child(3){order:2;}
.footer :nth-child(4){order:1}

  .copy {
    font-size: 60%;
    font-family: 'Noto Sans JP',sans-serif;
    letter-spacing: .5px;
    margin-top: 5%;
    margin-right: 28%;
    display: flex;
    justify-content: flex-end;
 }
 .social-link {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 3.3rem !important;
  font-size: .5rem;
}
.divSocial{
  margin-left: 10%;
}
 .logoSofttek {
    margin-left: 8%;
    width: 30%;
    height: 45%;
    margin-top: 10%;
    margin-bottom: 10%;
}
 .iconsSocial{
  margin-bottom: 5% !important;
  margin-top: 2% !important;
  margin-left: 12%;
 }
 .font {
  font-size: 65%;
  margin-left: 6%;
  padding: 2%;
  line-height: 1.8;
 }
  .terms{
   font-family: 'Noto Sans JP', sans-serif;
   color:rgba(205, 208, 216);
   font-size: 60%;
  }
  .place{
    padding: 2%;
    margin: 0;
  }
  .mobile{
    margin-left: 25.9%;
    margin-bottom: 6%;
  }
}
