.marginTxt {
  margin-left: 0%;
  padding-left: 0%;
}


.fondoInitiative {
  background-image:
    url("../../../../assets/img/Icons/background/circulong-01.svg"),
    url("../../../../assets/img/Icons/background/flechabg.svg");
  background-position-x: 92vw, -3.8vw;
  background-position-y: 3rem, 20rem;
  background-repeat: no-repeat;
  background-size: 12%;
  padding: 4% 0;
}

/*************************************************/
/********** Versión mobile de dts    *************/
/*************************************************/

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .fondoInitiative {
    background-position-y: 4rem, 25rem;
    background-size: 15%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .fondoInitiative {
    background-position-x: 82vw, -8vw;
    background-position-y: 2rem, 45rem;
    background-size: 25%;
  }
}
