.card {
  height: 483px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f8fa;
  border-radius: calc(2rem - 8px);
  border: 0;
}

.center-cropped {
  border-top-left-radius: calc(2rem - 8px);
  border-top-right-radius: calc(2rem - 8px);
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card:hover,
.card:hover .card__title,
.card:hover .card__subtitle,
.card:hover .card__text,
.card:hover .card__button {
  background-color: #506ff4;
  fill: #ffffff !important;
  color: #ffffff !important;
  border-radius: calc(2rem - 2px);
}

.card__title {
  font-size: 130%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  text-align: left;
  color: #092c66;
}

.card__text {
  font-family: Muli;
  font-size: 95%;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: 0.3px;
  text-align: left;
  color: #6a7c8d;
}

.card__subtitle {
  font-size: 70%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #506ff4;
  text-transform: uppercase;
}

.card__button {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 95%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #00c18c;
  fill: #00c18c;
  text-transform: uppercase;
  position: absolute;
  bottom: 22px;
  left: 23px;
}

.card__button-image {
  width: 8%;
  margin-left: 2%;
  margin-bottom: 1%;
}

.card__button:hover {
  text-decoration: none;
}

.card__gallerie-title {
  color: #092c66;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 230%;
  margin-top: 5%;
  margin-bottom: 2.5%;
  display: block;
  text-align: left;
}

.card__gallerie-subtitle {
  color: #092c66;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 160%;
  margin-top: 5%;
  margin-bottom: 2.5%;
  display: block;
  text-align: left;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .card__gallerie-title {
    font-size: 205%;
    text-align: center;
    margin: 0%;
    margin-top: 8%;
    margin-bottom: 6%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .card__gallerie-title {
    font-size: 155%;
  }
}
