.subtitleDts {
  font-size: 290%;
  color: #092C66;
  font-style: bolder;
  margin-bottom: 1%;
  font-family: 'Noto Sans JP', sans-serif;
}
/*************************************************/
/********** Versión mobile           *************/
/*************************************************/

