.overlay-init {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300 !important; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  background-color: rgba(36, 54, 71, 0.5);
  
}

.wrap-overlay {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.straps-pos {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 70%;
  bottom: 10%; 
  left: 85%;
  right: 5%;
  
}

.blink {
  animation: blinker 5.5s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0.0; }
}

@-webkit-keyframes one {
    100%, 0% {height: 10px;}
    80% {height: 100%;}
} 


.li {
  background: linear-gradient(to bottom, #13baaf, #13baaf) no-repeat 0 0;
  transform: rotate(180deg);
  background-size: 4px 0%;
  transition: 2s;
  margin: 10px;
  width: 10px;
  height: 66px;
  display: inline-block;
  border-radius: 100px;
}

.animate-me{
animation: one 3s .5s ease infinite;
border-radius: 50px;

}
.li:first-child{height: 33px;}
.li:first-child .animate-me{
background: linear-gradient(to bottom, #36b874, #36b874) no-repeat;
animation-delay: .25s;
}

.li:nth-child(2) .animate-me {
background: linear-gradient(to bottom, #13baaf, #13baaf) no-repeat 0 0;
}

.li:last-child{height: 99px;}
.li:last-child .animate-me{
background: linear-gradient(to bottom, #014260, #014260) no-repeat;
animation-delay: .75s;
}
