.pretitle {
  text-transform: uppercase;
  font-size: 105%;
  letter-spacing: 1.5px;
  color: #506FF4;
  /* font-weight: 900; */
  font-family: 'Noto Sans JP', sans-serif;
}

.title {
  font-size: 280%;
  color: #092C66;
  /* font-weight: 900; */
  font-family: 'Noto Sans JP', sans-serif;
}

.content {
  width: 90%;
  margin: auto;
}