.general-container {
  height: 43rem !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}